<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="6">
                    <h2><strong>Basic Settings</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <b-form @submit.prevent="updateBasic()">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                            </div>
                            <div class="col-md-4">
                                <div class="form-group text-center">
                                    <div v-if="form.logo" class="my-2">
                                        <img :src="form.logo" class="img-fluid" style="height:100px">
                                    </div>

                                    <b-form-group id="input-group-2" label="School Logo:">
                                        <input type="file" @change="uploadImage" accept="image/*" name="logo" class="form-control">
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-6">
                                <b-form-group label="School Name:">
                                <b-form-input v-model="form.school_name" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group label="Motto:">
                                <b-form-input v-model="form.slogan"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group label="Initial:">
                                <b-form-input v-model="form.ini"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group label="Email address:">
                                <b-form-input v-model="form.email" type="email" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group id="input-group-2" label="Phone Number:">
                                <b-form-input v-model="form.phone" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-12">
                                <b-form-group id="input-group-2" label="Address:">
                                <b-form-input v-model="form.address" required></b-form-input>
                                </b-form-group>
                            </div>

                            
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-danger btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { Form, HasError, AlertError, AlertSuccess } from 'vform';

    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
               
                form: new Form({
                    id: '',
                    school_name: '',
                    slogan: '',
                    ini:'',
                    email: '',
                    phone: '',
                    address: '',
                    current_session: '',
                    current_term: '',
                    logo: '',
                }),
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            this.load();
        },

        methods: {
            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/setting/basic-settings')
                .then(res => {
                    this.form = res.data.data;
                    if(res.data.data.logo==null){
                        this.form.logo = require("@/assets/images/logo/logoo.png");
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            updateBasic(){
                if(this.is_busy) return;
                this.is_busy = true;
                axios.put('/setting/basic-settings/'+this.form.id, this.form)
                .then(()=>{
                    Swal.fire(
                        'Updated!',
                        'Settings has been updated.',
                        'success'
                    )
                })

                .catch(() => {
                    Swal.fire(
                        'error!',
                        'Settings not updated.',
                        'error'
                    )
                })
                .finally(() => {
                    this.is_busy = false;
                    this.load();
                })       
            },

            uploadImage(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                if(file['size'] < 2388608){
                    reader.onloadend = (file) => {
                        this.form.logo = reader.result;
                        console.log(this.form.logo)
                    }
                    reader.readAsDataURL(file);
                }
                else {
                    this.$toast.error({
                        component: ToastificationContent,
                        props: {
                            title: "An Error Occured",
                            text: "Oops, You are uploading a large file, try again. Upload file less that 3MB",
                            icon: "XIcon",
                            variant: "errror",
                        },
                    });
                    this.form.logo = require("@/assets/images/logo/logoo.png");
                }
            },
        },
  }
</script>